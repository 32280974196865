import comMyhttp from './request'
let request = comMyhttp.instance;
import qs from 'qs'
//短信验证码注册
export function registerLogin(data){
    return request({
        url:'/tl_en_school/pc/user/registerLogin',
        method:'post',
        data:qs.stringify(data),
    })
}
//获取盐值
export function getSalt(params){
    return request({
        url:'/tl_en_school/pc/user/getSalt',
        method:'get',
        params,
    })
}
//获取短信验证码 
export function sendCode(data){
    return request({
        url:'/tl_en_school/pc/user/sendCode',
        method:'post',
        data:qs.stringify(data),
    })
}
//短信验证码直接登录
export function messageLogin(data){
    return request({
        url:'/tl_en_school/pc/user/messageLogin',
        method:'post',
        data:qs.stringify(data),
    })
}
//绑定手机号 
export function bindPhone(params){
    return request({
        url:'/tl_en_school/pc/user/bindPhone',
        method:'post',
        params,
    })
}
//用户设置密码 
export function setPassword(data){
    return request({
        url:'/tl_en_school/pc/user/setPassword',
        method:'post',
        data:qs.stringify(data),
    })
}
//账号密码登录
export function accountLogin(data){
    return request({
        url:'/tl_en_school/pc/user/accountLogin',
        method:'post',
        data:qs.stringify(data),
    })
}
//退出登录 
export function exitApp(data){
    return request({
        url:'/tl_en_school/pc/user/exitApp',
        method:'post',
        data:qs.stringify(data),
    })
}
//刷新用户信息 
export function refreshToken(data){
    return request({
        url:'/tl_en_school/pc/user/refreshToken',
        method:'post',
        data:qs.stringify(data),
    })
}
//用户忘记密码接口 
export function forgetPassword(data){
    return request({
        url:'/tl_en_school/pc/user/forgetPassword',
        method:'post',
        data:qs.stringify(data),
    })
}
//用户更新密码
export function updatePassword(data){
    return request({
        url:'/tl_en_school/pcUser/updatePassword',
        method:'post',
        data:qs.stringify(data),
    })
}
//修改用户信息
export function updateAppUser(data){
    return request({
        url:'/tl_en_school/pc/user/updateAppUser',
        method:'post',
        data:qs.stringify(data),
    })
}
//根据手机号查询用户信息
export function getUserByPhone(params){
    return request({
        url:'/tl_en_school/pc/user/getUserByPhone',
        method:'get',
        params,
    })
}
//上传图片接口 
export function uploadPic(data){
    return request({
        url:'/tl_en_school/api/file/upload',
        method:'post',
        data,
    })
}
//获取分类id列表(视频模块)
export function categoryList(params){
    return request({
        url:'/tl_en_school/pc/videoCourse/categoryList',
        method:'get',
        params,
    })
}
//获取课程列表(视频模块)
export function curriculumList(params){
    return request({
        url:'/tl_en_school/pc/videoCourse/list',
        method:'get',
        params,
    })
}
//视频详情 
export function getInfoCustomer(params){
    return request({
        url:'/tl_en_school/pc/videoCourse/getInfo2Customer',
        method:'get',
        params,
    })
}
//视频播放信息  
export function videoCourse(params){
    return request({
        url:'/tl_en_school/pc/videoCourse/watch',
        method:'get',
        params,
    })
}
//首页展示接口
export function indexList(params){
    return request({
        url:'/tl_en_school/pc/pendant/list',
        method:'get',
        params,
    })
}
//个人中心浏览记录 
export function getPageViews(params){
    return request({
        url:'/tl_en_school/pc/videoCourse/getPageViews',
        method:'get',
        params,
    })
}
//订单列表 
export function orderList(params){
    return request({
        url:'/tl_en_school/pc/VideoOrder/myOrderList',
        method:'get',
        params,
    })
}
//充值接口列表 
export function videoPackageList(params){
    return request({
        url:'/tl_en_school/pc/VideoPackage/videoPackageList',
        method:'get',
        params,
    })
}
//关于我们
export function getDataAbout(params){
    return request({
        url:'/tl_en_school/pc/relatedInstruction/getData',
        method:'get',
        params,
    })
}
//联系我们接口
export function getContactUs(params){
    return request({
        url:'/tl_en_school/h5/relatedInstruction/contactUs',
        method:'get',
        params,
    })
}
//获取会员等级 
export function getDataVip(params){
    return request({
        url:'/tl_en_school/pc/userGrade/getData',
        method:'get',
        params,
    })
}
//pc端扫码获取token 
export function scanToken(){
    return request({
        url:'/tl_en_school/pc/user/scanToken',
        method:'post',
    })
}
//轮询扫码token认证
export function scanLogin(data){
    return request({
        url:'/tl_en_school/pc/user/scanLogin',
        method:'post',
        headers:{
            scanToken:data.scanToken
        }
    })
}
//下单接口 
export function purchase(params){
    return request({
        url:'/tl_en_school/pc/VideoOrder/purchase',
        method:'get',
        params
    })
}
//支付宝支付接口  
export function alipay(data){
    return request({
        url:'/tl_en_school/pc/pays/alipay',
        method:'post',
        data:qs.stringify(data)
    })
}
//微信支付 
export function wxPay(data){
    return request({
        url:'/tl_en_school/pc/pays/wxPayV2',
        method:'post',
        data:qs.stringify(data)
    })
}
//支付宝查询支付状态 
export function getOrderStatus(data){
    return request({
        url:'/tl_en_school/pc/VideoOrder/getOrderStatus',
        method:'post',
        data:qs.stringify(data)
    })
}
//获取套餐时长 
export function getVideoHour(params){
    return request({
        url:'/tl_en_school/pc/videoTimes/getVideoHour',
        method:'get',
        params
    })
}
//套餐明细 
export function getUseLog(params){
    return request({
        url:'/tl_en_school/pc/videoTimes/getUseLog',
        method:'get',
        params
    })
}
//获取支付方式 
export function payMod(data){
    return request({
        url:'/tl_en_school/pc/payMod/list',
        method:'post',
        data:qs.stringify(data)
    })
}
//文章分类接口
export function newCategory(){
    return request({
        url:'/tl_en_school/pc/article/category/list',
        method:'get',
    })
}
//文章列表接口
export function getNewsList(params){
    return request({
        url:'tl_en_school/pc/article/list',
        method:'get',
        params
    })
}
//文章详情接口
export function getNewsDetail(params){
     return request({
         url:'tl_en_school/pc/article/detail',
         method:'get',
         params
     })
}
